import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"

const AdminPage = () => {

  return (
    <Layout pageInfo={{ pageName: "Admin" }}>

      <p>Update quotes and comments from here</p>

    </Layout>
  )
}

export default AdminPage